import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

// MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { magentaColor } from 'services/constants';

// CSS
import './index.css';

// Translations
import './services/i18next';

// Roboto Font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Main Component
import App from './App';

// --------------------------------------------------------------------------------- \\
// --------------------------------- MUI THEME ------------------------------------- \\
// --------------------------------------------------------------------------------- \\

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        magenta: true;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        magenta: Palette['primary'];
    }
    interface PaletteOptions {
        magenta: PaletteOptions['primary'];
    }
}

export const theme = createTheme({
    palette: {
        magenta: {
            light: '#d80080',
            main: magentaColor,
            dark: '#890f61',
            contrastText: '#fff',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                style: {
                    fontSize: 16,
                    textTransform: 'none',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                "root": {
                    "&.Mui-selected": {
                        "backgroundColor": "transparent",
                        "fontWeight": "600",
                    },
                    "&.Mui-selected:hover": {
                        "backgroundColor": "rgba(0, 0, 0, 0.1)",
                    },
                }
            },
        },
    },
});

// --------------------------------------------------------------------------------- \\
// ------------------------------------ ROOT --------------------------------------- \\
// --------------------------------------------------------------------------------- \\

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
