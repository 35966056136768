import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';

// CSS
import './AppBar.css';

// MUI
import { Grid, IconButton, ListItemButton, Drawer, Divider } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';

// Assets
import DiscoviaLogo from 'assets/images/discovia.png';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- APP BAR ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AppBar() {

    const navigate = useNavigate();
    const { mediaSM, mediaLG } = useMediaQueries();
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <header> 
            <Grid
                container alignItems="center"
                className="AppBar"
            >
                {/* -------------------- LOGO -------------------- */}
                <Grid
                    item xs="auto"
                    className="AppBar-nav"
                >
                    <ListItemButton
                        onClick={() => navigate('/home')}
                        style={{ padding: '0px 8px', height: '100%' }}
                    >
                        <img
                            alt="Logo de DISCOVIA"
                            src={DiscoviaLogo}
                            width={142}
                            height={60}
                            loading="eager"
                            className="AppBar-logo"
                        />
                    </ListItemButton>
                </Grid>
                <Grid
                    item xs
                />
                {/* -------------------- MENU -------------------- */}
                {mediaLG ? (
                    <MenuList />
                ) : (
                    <>
                        <Grid
                            item xs="auto"
                            style={{ padding: '8px 16px' }}
                        >
                            <IconButton onClick={() => setOpenMenu(true)}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Drawer
                            open={openMenu}
                            onClose={() => setOpenMenu(false)}
                            anchor="right"
                            PaperProps={{ style: { width: mediaSM ? undefined : '100%' }}}
                            ModalProps={{ style: { zIndex: 9999 } }}
                        >
                            <Grid
                                container justifyContent="flex-end"
                                className="AppBar-close-menu"
                            >
                                <IconButton onClick={() => setOpenMenu(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                            <Divider />
                            <Grid container>
                                <MenuList
                                    onClose={() => setOpenMenu(false)}
                                />
                            </Grid>
                            <Divider />
                        </Drawer>
                    </>
                )}
            </Grid>
        </header>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- MENU ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

function MenuList({ onClose } : { onClose?: VoidFunction }) {

    let navigate = useNavigate();
    const { t } = useTranslation();
    
    const onNavigate = (url: string) => {
        if (onClose) onClose();
        navigate(url);
    };

    return (
        <>
            {/* -------------------- HOME -------------------- */}
            <Grid
                item xs={12} lg="auto"
                className="AppBar-nav"
            >
                <ListItemButton
                    onClick={() => onNavigate('/home')}
                    selected={!!(useMatch('/home'))}
                    className="AppBar-nav-button"
                >
                    {t('home')}
                </ListItemButton>
            </Grid>
            {/* -------------------- SOLUTION -------------------- */}
            <Grid
                item xs={12} lg="auto"
                className="AppBar-nav"
            >
                <ListItemButton
                    onClick={() => onNavigate('/solutions')}
                    selected={!!(useMatch('/solutions'))}
                    className="AppBar-nav-button"
                >
                    {t('solutions')}
                </ListItemButton>
            </Grid>
            {/* -------------------- EXPERTISE -------------------- */}
            <Grid
                item xs={12} lg="auto"
                className="AppBar-nav"
            >
                <ListItemButton
                    onClick={() => onNavigate('/expertise')}
                    selected={!!(useMatch('/expertise'))}
                    className="AppBar-nav-button"
                >
                    {t('expertise')}
                </ListItemButton>
            </Grid>
            {/* -------------------- ABOUT US -------------------- */}
            <Grid
                item xs={12} lg="auto"
                className="AppBar-nav"
            >
                <ListItemButton
                    onClick={() => onNavigate('/about-us')}
                    selected={!!(useMatch('/about-us'))}
                    className="AppBar-nav-button"
                >
                    {t('about_us')}
                </ListItemButton>
            </Grid>
            {/* -------------------- CONTACT -------------------- */}
            {/* <Grid
                item xs={12} lg="auto"
                className="AppBar-nav"
            >
                <ListItemButton
                    onClick={() => onNavigate('/contact')}
                    selected={!!(useMatch('/contact'))}
                    className="AppBar-nav-button"
                >
                    {t('contact')}
                </ListItemButton>
            </Grid> */}
        </>
    );
}
