import { useState } from 'react';

// Views
import { Layout, NavButton } from '../Layout';
import Professionals from './Professionals';
import Travelers from './Travelers';

// Assets
import ParolesDeProfessionnelsImg from 'assets/images/locomotive/paroles-de-professionnels.jpg';
import ParolesDeVoyageursImg from 'assets/images/locomotive/paroles-de-voyageurs.jpg';

// CSS
import '../locomotive.css';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ INTERVIEWS ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Interviews() {

    const [page, setPage] = useState(0);

    return (
        <>
            {page === 0 ? (
                <Layout title="Le bilan">
                    <NavButton
                        onClickBtn1={() => setPage(1)}
                        onClickBtn2={() => setPage(2)}
                        srcBtn1={ParolesDeProfessionnelsImg}
                        srcBtn2={ParolesDeVoyageursImg}
                    />
                </Layout>
            ) : page === 1 ? (
                <Layout
                    title="Paroles de professionnels"
                    onClickToBackButton={() => setPage(0)}
                >
                    <Professionals />
                </Layout>
            ) : (
                <Layout
                    title="Paroles de voyageurs"
                    onClickToBackButton={() => setPage(0)}
                >
                    <Travelers />
                </Layout>
            )}
        </>
    );
}
