import { useEffect, useState } from "react";
import Slider from "react-slick";

// Assets
import TrainTicketNoemie from 'assets/images/locomotive/train-ticket-noemie.jpg';
import TrainTicketMajida from 'assets/images/locomotive/train-ticket-majida.jpg';
import TrainTicketMarinette from 'assets/images/locomotive/train-ticket-marinette.jpg';
import TrainTicketRomain from 'assets/images/locomotive/train-ticket-romain.jpg';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ TRAVELERS ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const travelers = [
    {
        ticket: TrainTicketNoemie,
        audio: 'https://discovia.fr/resources/audio-noemie.mp3',
    },
    {
        ticket: TrainTicketMajida,
        audio: 'https://discovia.fr/resources/audio-majida.mp3',
    },
    {
        ticket: TrainTicketMarinette,
        audio: 'https://discovia.fr/resources/audio-marinette.mp3',
    },
    {
        ticket: TrainTicketRomain,
        audio: 'https://discovia.fr/resources/audio-romain.mp3',
    },
];

export default function Travelers() {

    const [slideHeight, setSlideHeight] = useState(0);
    const [visible, setVisible] = useState(false);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: function() {
            const audios = document.getElementsByTagName('audio');
            Object.values(audios).forEach(audio => { if (audio.played) audio.pause(); });
        },
    };

    useEffect(() => {
        
        const onResize = () => {
            let sliderContainer = document.getElementById('sliderContainer') as HTMLElement;
            setSlideHeight(sliderContainer.offsetHeight - 68);
            setVisible(true);
        };
        document.addEventListener('resize', onResize);

        onResize();

        return () => {
            document.removeEventListener('resize', onResize);
        };

    }, []);

    return (
        <div
            id="sliderContainer"
            className="slick-container"
        >
            {visible && (
                <Slider {...sliderSettings}>
                    {travelers.map((traveler, index) => (
                        <div key={index}>
                            <div
                                className="loco-item"
                                style={{ backgroundImage: `url(${traveler.ticket})`, height: slideHeight }}
                            >
                                <audio
                                    id={`travelerAudio${index}`}
                                    controls
                                    src={traveler.audio}
                                    className="loco-audio"
                                />
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
}
