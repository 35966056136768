import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './DigitalWall.css';

// Lodash
import { debounce } from 'lodash';

// MUI
import { Button, Grid } from '@mui/material';
import { SendRounded as SendIcon } from '@mui/icons-material';

// Services
import { API_URL } from 'services/constants';

// Components
import Layout from 'components/Layout';

// Assets
import DigitalWallImg from 'assets/images/digital-wall/mur-digital.jpg';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ DIGITAL WALL -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function DigitalWall() {

    const { t } = useTranslation();
    
    const [state, setState] = useState({
        width: 700,
        height: 400,
    });

    useEffect(() => {

        const onResizeIframe = () => {

            let iframeDiv = document.getElementById('DigitalWall-iframe') as HTMLElement;

            if (iframeDiv) {
                setState(prevState => ({
                    ...prevState,
                    width: iframeDiv.offsetWidth,
                    height: (iframeDiv.offsetWidth * prevState.height) / prevState.width,
                }));
            }
        };

        onResizeIframe();
        window.addEventListener('resize', debounce(onResizeIframe, 200));

        return () => {
            window.removeEventListener('resize', onResizeIframe);
        };

    }, []);

    return (
        <>
            {/* -------------------- TITLE -------------------- */}
            <Layout containerProps={{ backgroundColor: 'rgb(235, 235, 235)' }}>
                <div
                    id="DigitalWall-section"
                    className="title-container"
                >
                    <h1>{t('digital_wall_translations.1')}</h1>
                </div>
            </Layout>
            <Layout>
                <div style={{ padding: 24, textAlign: 'justify' }}>
                    {t('digital_wall_translations.2')}
                </div>
                <div style={{ padding: '0px 24px', textAlign: 'center' }}>
                    <img
                        alt="Illustration du Mur des Soft skills"
                        src={DigitalWallImg}
                        width={512}
                        height={341}
                        className="DigitalWall-image"
                    />
                </div>
                <div className="title-container" style={{ color: 'gray' }}>
                    <h1>{t('digital_wall_translations.3')}</h1>
                </div>
                <div style={{ padding: '0px 24px' }}>
                    <ul className="DigitalWall-ul">
                        <li>{t('digital_wall_translations.4')}</li>
                        <li>{t('digital_wall_translations.5')}</li>
                        <li>{t('digital_wall_translations.6')}</li>
                        <li>{t('digital_wall_translations.7')}</li>
                    </ul>
                </div>
                <div className="title-container" style={{ color: 'gray' }}>
                    <h1>{t('digital_wall_translations.9')}</h1>
                </div>
                <Grid
                    container alignItems="flex-start"
                    style={{ padding: 12 }}
                >
                    <Grid
                        item xs={12} sm={6} xl={4}
                        style={{ padding: 12 }}
                    >
                        <div className="DigitalWall-iframe">
                            <iframe
                                width={state.width}
                                height={state.height}
                                src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6983042851475456000?compact=1"
                                title="Pierre HOERTER, président de DISCOVIA évoque les finalités et atouts du Mur"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                style={{ border: 0 }}
                            />
                        </div>
                        <a
                            href="https://www.linkedin.com/posts/agenceautonomy_pierre-hoerter-au-salon-e-tonomy-job-activity-6983043648225427456-1MWp/?trk=public_profile_like_view&originalSubdomain=fr"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: 16 }}
                        >
                            {t('digital_wall_translations.10')}
                        </a>
                    </Grid>
                    <Grid
                        item xs={12} sm={6} xl={4}
                        style={{ padding: 12 }}
                    >
                        <div id="DigitalWall-iframe">
                            <iframe
                                width={state.width}
                                height={state.height}
                                src="https://www.youtube.com/embed/dBcw1_FxzD4"
                                title="Mur Des Softskills by Discovia"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                style={{ border: 0 }}
                            />
                        </div>
                        <span style={{ fontSize: 16 }}>
                            {t('digital_wall_translations.11')}
                        </span>
                    </Grid>
                    <Grid
                        item xs={12} sm={6} xl={4}
                        style={{ padding: 12 }}
                    >
                        <div
                            className="DigitalWall-image-2"
                            style={{ width: state.width, height: state.height }}
                        />
                        <a
                            href={`${API_URL}jerome-apffel-mur-en-afest.pdf`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: 16 }}
                        >
                            {t('digital_wall_translations.12')}
                        </a>
                    </Grid>
                </Grid>
                <div className="title-container">
                    <Button
                        href="mailto:contact@discovia.fr"
                        color="primary" variant="contained"
                        endIcon={<SendIcon />}
                    >
                        {t('digital_wall_translations.8')}
                    </Button>
                </div>
            </Layout>
        </>
    );
}
