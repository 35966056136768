import React from 'react';

// MUI
import { Grid } from '@mui/material';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- LAYOUT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Layout({ children, containerProps } : { children: React.ReactNode, containerProps?: React.CSSProperties }) {
    return (
        <Grid
            container
            style={containerProps}
        >
            <Grid
                item xs={false} md={1} lg={2} xl={3}
            />
            <Grid
                item xs={12} md={10} lg={8} xl={6}
                container flexDirection="column"
            >
                {children}
            </Grid>
        </Grid>
    );
}
