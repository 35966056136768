import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './LegalNotice.css';

// Components
import HeadAttributes from 'components/HeadAttributes';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ LEGAL NOTICE -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function LegalNotice() {

    const { t } = useTranslation();
    
    useEffect(() => {
        if (window.scrollY > 0) {
            window.scroll(0, 0);
        }
    }, []);

    return (
        <>
            <HeadAttributes
                title={t('legal_notice')}
                noindex
            />
            <div className="LegalNotice">
                <div>
                    {/* -------------------- EDITING -------------------- */}
                    <div>
                        <h2>Édition du site</h2>
                        <p>
                            Site : https://discovia.fr<br />
                            Société : DISCOVIA<br />
                            SAS au capital de 2200 euros<br />
                            Inscrite au R.C.S. de STRASBOURG sous le numéro 892229782<br />
                            Siège social : 13 Rue du Bain aux Plantes, 67000 STRASBOURG<br />
                            Représentant : Pierre HOERTER
                        </p>
                    </div>
                    {/* -------------------- HOSTING -------------------- */}
                    <div style={{ marginTop: 24 }}>
                        <h2>Hébergement</h2>
                        <p>
                            Le site est hébergé par la société OVH SAS, situé 2 rue Kellermann - 59100 Roubaix - France
                        </p>
                    </div>
                    {/* -------------------- DIRECTOR -------------------- */}
                    <div style={{ marginTop: 24 }}>
                        <h2>Directeur de la publication</h2>
                        <p>
                            Le directeur de la publication du site est Judith FISCHER.
                        </p>
                    </div>
                    {/* -------------------- DPO -------------------- */}
                    <div style={{ marginTop: 24 }}>
                        <h2>Délégué à la protection des données</h2>
                        <p>
                            Le délégué à la protection des données est Judith FISCHER.<br />
                            Email : judith.fischer@discovia.fr.<br />
                            Elle est à votre disposition pour toute question relative à la protection de vos données personnelles.
                        </p>
                    </div>
                    {/* -------------------- CONTACT -------------------- */}
                    <div style={{ marginTop: 24 }}>
                        <h2>Nous contacter</h2>
                        <p>
                            Par téléphone : +33 (0)9 78 80 14 13<br />
                            Par email : contact@discovia.fr<br />
                            Par courrier : 17 Rue de la Boucherie, 67120 MOLSHEIM
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
