
// MUI
import { Avatar, Grid } from '@mui/material';

// Assets
import AvatarSebastien from 'assets/images/locomotive/avatar-sebastien.png';
import AvatarElodie from 'assets/images/locomotive/avatar-elodie.png';

// CSS
import styles from './Professionals.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- PROFESSIONALS -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Professionals() {

    const onPauseAudio = (pro: string) => {

        let audio = document.getElementById(`audioPro${pro}`) as HTMLAudioElement;
        
        if (audio.played) {
            audio.pause();
        }
    };

    return (
        <Grid
            flex={1}
            container
            alignItems="center"
            justifyContent="space-around"
        >
            <div className={styles.card}>
                <Grid 
                    container
                    justifyContent="center"
                    marginBottom={2}
                >
                    <Avatar
                        src={AvatarSebastien}
                        sx={{
                            width: 200,
                            height: 200,
                            border: '4px solid rgb(120, 184, 144)',
                        }}
                    />
                </Grid>
                <div className={styles.title}>
                    Sébastien Viehe
                </div>
                <div className={styles.subtitle}>
                    Responsable des services périscolaires de la ville de Mutzig
                </div>
                <div>
                    <audio
                        id="audioProSebastien"
                        src="https://discovia.fr/resources/audio-pro-sebastien.mp3"
                        controls
                        onPlay={() => onPauseAudio('Elodie')}
                    />
                </div>
            </div>
            <div className={styles.card}>
                <Grid 
                    container
                    justifyContent="center"
                    marginBottom={2}
                >
                    <Avatar
                        src={AvatarElodie}
                        sx={{
                            width: 200,
                            height: 200,
                            border: '4px solid rgb(120, 184, 144)',
                        }}
                    />
                </Grid>
                <div className={styles.title}>
                    Élodie Wanner
                </div>
                <div className={styles.subtitle}>
                    Gestionnaire des ressources humaines à l'Opal
                </div>
                <div>
                    <audio
                        id="audioProElodie"
                        src="https://discovia.fr/resources/audio-pro-elodie.mp3"
                        controls
                        onPlay={() => onPauseAudio('Sebastien')}
                    />
                </div>
            </div>
        </Grid>
    );
}
