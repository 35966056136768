import { useState } from 'react';

// Views
import { Layout, NavButton } from "../Layout";
import Explanation from './Explanation';
import Partners from './Partners';

// Assets
import ExplicationDuProjetImg from 'assets/images/locomotive/explication-du-projet.jpg';
import ActeurDuProjetImg from 'assets/images/locomotive/acteurs-du-projet.jpg';

// CSS
import '../locomotive.css';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- PROJECT ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Start() {

    const [page, setPage] = useState(0);

    return (
        <>
            {page === 0 ? (
                <Layout title="Le projet">
                    <NavButton
                        onClickBtn1={() => setPage(1)}
                        onClickBtn2={() => setPage(2)}
                        srcBtn1={ExplicationDuProjetImg}
                        srcBtn2={ActeurDuProjetImg}
                    />
                </Layout>
            ) : page === 1 ? (
                <Layout
                    title="Explication du projet"
                    onClickToBackButton={() => setPage(0)}
                >
                    <Explanation />
                </Layout>
            ) : (
                <Layout
                    title="Les acteurs du projet"
                    onClickToBackButton={() => setPage(0)}
                >
                    <Partners />
                </Layout>
            )}
        </>
    );
}
