import { PropsWithChildren } from 'react';
import { Routes, Route, Navigate, useMatch } from 'react-router-dom';

// Components
import AppBar from 'components/AppBar';
import Footer from 'components/Footer';

// Views
import Home from 'views/Home';
import AboutUs from 'views/AboutUs';
import Expertise from 'views/Expertise';
import Solutions from 'views/Solutions';
import Contact from 'views/Contact';
import LegalNotice from 'views/LegalNotice';
import Bafa from 'views/Bafa';

// Locomotive
import Start from 'views/Locomotive/Start';
import Result from 'views/Locomotive/Result';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- APP ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function App() {
    return (
        <Routes>
            <Route index path="home" element={<Layout><Home /></Layout>} />
            <Route path="about-us" element={<Layout><AboutUs /></Layout>} />
            <Route path="expertise" element={<Layout><Expertise /></Layout>} />
            <Route path="solutions" element={<Layout><Solutions /></Layout>} />
            <Route index path="contact" element={<Layout><Contact /></Layout>} />
            <Route index path="legal-notice" element={<Layout><LegalNotice /></Layout>} />
            <Route index path="bafa" element={<Bafa />} />
            <Route index path="locomotive/start" element={<Start />} />
            <Route index path="locomotive/result" element={<Result />} />
            <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- LAYOUT ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

function Layout(props: PropsWithChildren) {

    const matchHome = useMatch('/home');

    return (
        <>
            <AppBar />
            <div
                id="App"
                style={{ marginTop: matchHome ? 0 : undefined }}
            >
                {props.children}
            </div>
            <Footer />
        </>
    );
}
