import { useEffect, useState } from 'react';
import Slider from 'react-slick';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- PARTNERS ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const testimonials = [
    {
        title: "Sébastien Viehe",
        subtitle: "Responsable des services périscolaires de la ville de Mutzig",
        src: "https://discovia.fr/resources/video-pro-sebastien.mp4",
    },
    {
        title: "Élodie Wanner",
        subtitle: "Gestionnaire des ressources humaines à l'Opal",
        src: "https://discovia.fr/resources/video-pro-elodie.mp4",
    },
    {
        title: "Marinette",
        subtitle: "Voyageuse accompagnée dans le projet Loco Périsco",
        src: "https://discovia.fr/resources/video-marinette.mp4",
    },
];

export default function Partners() {

    const [slideHeight, setSlideHeight] = useState(0);
    const [visible, setVisible] = useState(false);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: function() {
            const videos = document.getElementsByTagName('video');
            Object.values(videos).forEach(video => { if (video.played) video.pause(); });
        },
    };

    useEffect(() => {
        
        const onResize = () => {
            let sliderContainer = document.getElementById('sliderContainer') as HTMLElement;
            setSlideHeight(sliderContainer.offsetHeight - 68);
            setVisible(true);
        };
        document.addEventListener('resize', onResize);

        onResize();

        return () => {
            document.removeEventListener('resize', onResize);
        };

    }, []);

    return (
        <div
            id="sliderContainer"
            className="slick-container"
        >
            {visible && (
                <Slider {...sliderSettings}>
                    {testimonials.map((testimony, index) => (
                        <div key={index}>
                            <div
                                className="loco-item"
                                style={{ height: slideHeight }}
                            >
                                <div className="logo-video-header">
                                    <span className="loco-video-title">
                                        {testimony.title}
                                    </span>
                                    <span className="loco-video-subtitle">
                                        {testimony.subtitle}
                                    </span>
                                </div>
                                <video
                                    controls
                                    src={testimony.src}
                                    className="loco-video"
                                />
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
}
