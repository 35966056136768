
// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- API URL ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const API_URL = window.origin + '/';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- COLORS ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const blueColor = '#2579d2';
export const magentaColor = '#ac0066';
export const greenColor = '#2e7d32';
export const yellowColor = '#f5b200';
