import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Services
import { API_URL } from 'services/constants';

// Components
import HeadAttributes from 'components/HeadAttributes';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- CONTACT ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Contact() {

    const { t } = useTranslation();
    
    useEffect(() => {
        if (window.scrollY > 0) {
            window.scroll(0, 0);
        }
    }, []);

    return (
        <>
            <HeadAttributes
                title={t('contact')}
                canonical={API_URL + 'contact'}
            />
        </>
    );
}
