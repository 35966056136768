import { Helmet } from 'react-helmet-async';

// Services
import { truncateLabel } from 'services/utils';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- HEAD ATTRIBUTES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function HeadAttributes({
    title,
    description,
    canonical,
    noindex = false,
} : {
    title: string,
    description?: string,
    canonical?: string,
    noindex?: boolean,
}) {
    return (
        <Helmet>
            <title>
                {truncateLabel(title, 20)} | DISCOVIA
            </title>
            <meta
                name="description"
                content={description}
            />
            {canonical && (
                <link rel="canonical" href={canonical} />
            )}
            {noindex && (
                <meta name="robots" content="noindex" />
            )}
        </Helmet>
    );
}
