import { useTranslation } from 'react-i18next';

// CSS
import './ZeroBarrier.css';

// MUI
import { Grid, Button } from '@mui/material';
import { SendRounded as SendIcon } from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';

// Components
import Layout from 'components/Layout';

/* * *
* Assets
*/

import ZeroBarrierLogo from 'assets/images/zerobarrier/zerobarrier.png'

import { ReactComponent as LLPLogo } from 'assets/images/partners/llp.svg';
import { ReactComponent as EcvetLogo } from 'assets/images/partners/ecvet.svg';
import { ReactComponent as EQFLogo } from 'assets/images/partners/eqf.svg';
import { ReactComponent as ErasmusLogo } from 'assets/images/partners/erasmus.svg';
import { ReactComponent as EuropassLogo } from 'assets/images/partners/europass.svg';

import { ReactComponent as MinistereEnseignementLogo } from 'assets/images/partners/ministere-enseignement.svg';
import { ReactComponent as FranceCompetenceLogo } from 'assets/images/partners/france-competence.svg';
import { ReactComponent as ErasmusAgeanceLogo } from 'assets/images/partners/eramus-agence.svg';
import { ReactComponent as AG2RLogo } from 'assets/images/partners/ag2r.svg';
import { ReactComponent as EngieLogo } from 'assets/images/partners/engie.svg';

import { ReactComponent as CroixRougeLogo } from 'assets/images/partners/croix-rouge.svg';
import { ReactComponent as FederationEntrepriseLogo } from 'assets/images/partners/fei.svg';
import { ReactComponent as FranceActiveLogo } from 'assets/images/partners/france-active.svg';
import { ReactComponent as MFRLogo } from 'assets/images/partners/mfr.svg';
import { ReactComponent as CCALogo } from 'assets/images/partners/cca.svg';

// Logo Props
const logoProps : React.CSSProperties = {
    width: '100%',
    maxWidth: 120,
    height: 'auto',
    maxHeight: 60,
};

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ ZERO BARRIER -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ZeroBarrier() {

    const { t } = useTranslation();
    const { mediaDesktop } = useMediaQueries();
    
    return (
        <>
            {/* -------------------- TITLE & LOGO -------------------- */}
            <Layout containerProps={{ backgroundColor: 'rgb(235, 235, 235)' }}>
                <div
                    id="ZeroBarrier-section"
                    className="title-container"
                >
                    <img
                        alt="Logo ZERO BARRIER"
                        src={ZeroBarrierLogo}
                        loading="eager"
                        width={445}
                        height={60}
                        className="ZeroBarrier-logo"
                    />
                    <h1>{t('zerobarrier_translations.1')}</h1>
                </div>
            </Layout>
            <Layout>
                {/* -------------------- INTRO -------------------- */}
                <div style={{ padding: 24, textAlign: 'justify' }}>
                    <div>
                        {t('zerobarrier_translations.3')}
                    </div>
                    <div style={{ marginTop: 24 }}>
                        {t('zerobarrier_translations.5')}
                    </div>
                </div>
                {/* -------------------- VIDEO -------------------- */}
                <div style={{ padding: '0px 24px', textAlign: 'center' }}>
                    <video
                        src="https://discovia.fr/resources/zerobarrier-presentation.mp4"
                        autoPlay={mediaDesktop}
                        loop={mediaDesktop}
                        controls
                        muted
                        className="ZeroBarrier-video"
                    />
                </div>
                {/* -------------------- FONCTIONNALITIES -------------------- */}
                <div style={{ padding: 24, color: 'gray' }}>
                    <h1>{t('zerobarrier_translations.6')}</h1>
                </div>
                <Grid
                    container alignItems="center"
                    style={{ padding: 12 }}
                >
                    <Grid
                        item xs={12} md={6}
                        style={{ padding: 12 }}
                    >
                        <div
                            className="ZeroBarrier-image"
                        />
                    </Grid>
                    <Grid
                        item xs={12} md={6}
                        style={{ padding: 12 }}
                    >
                        <ul className="ZeroBarrier-ul">
                            <li>{t('zerobarrier_translations.7')}</li>
                            <li>{t('zerobarrier_translations.8')}</li>
                            <li>{t('zerobarrier_translations.9')}</li>
                            <li>{t('zerobarrier_translations.10')}</li>
                        </ul>
                    </Grid>
                </Grid>
                <div style={{ padding: '0px 24px' }}>
                    <Button
                        href="mailto:contact@discovia.fr"
                        variant="contained" color="primary"
                        endIcon={<SendIcon />}
                    >
                        {t('zerobarrier_translations.4')}
                    </Button>
                </div>
                {/* -------------------- PARTNERS -------------------- */}
                <div style={{ padding: 24, color: 'gray' }}>
                    <h1>{t('zerobarrier_translations.11')}</h1>
                </div>
                {/* -------------------- UNION EURO -------------------- */}
                <Grid
                    container spacing={3}
                    style={{ padding: 24 }}
                >
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="ZeroBarrier-card">
                            <div style={{ fontSize: 16, minHeight: 76 }}>
                                {t('zerobarrier_translations.12')}
                            </div>
                            <div
                                className="ZeroBarrier-partners"
                                style={{ marginTop: 12 }}
                            >
                                <LLPLogo style={logoProps} />
                            </div>
                            <div className="ZeroBarrier-partners">
                                <ErasmusLogo style={logoProps} />
                            </div>
                            <div className="ZeroBarrier-partners">
                                <EcvetLogo style={logoProps} />
                            </div>
                            <div className="ZeroBarrier-partners">
                                <EQFLogo style={logoProps} />
                            </div>
                            <div className="ZeroBarrier-partners">
                                <EuropassLogo style={logoProps} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="ZeroBarrier-card">
                            <div style={{ fontSize: 16, minHeight: 76 }}>
                                {t('zerobarrier_translations.13')}
                            </div>
                            <div
                                className="ZeroBarrier-partners"
                                style={{ marginTop: 12 }}
                            >
                                <MinistereEnseignementLogo style={logoProps} />
                            </div>
                            <div className="ZeroBarrier-partners">
                                <FranceCompetenceLogo style={logoProps} />
                            </div>
                            <div className="ZeroBarrier-partners">
                                <ErasmusAgeanceLogo style={logoProps} />
                            </div>
                            <div className="ZeroBarrier-partners">
                                <AG2RLogo style={logoProps}/>
                            </div>
                            <div className="ZeroBarrier-partners">
                                <EngieLogo style={logoProps} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="ZeroBarrier-card">
                            <div style={{ fontSize: 16, minHeight: 76 }}>
                                {t('zerobarrier_translations.14')}
                            </div>
                            <div
                                className="ZeroBarrier-partners"
                                style={{ marginTop: 12 }}
                            >
                                <MFRLogo style={logoProps}/>
                            </div>
                            <div className="ZeroBarrier-partners">
                                <FranceActiveLogo style={logoProps}/>
                            </div>
                            <div className="ZeroBarrier-partners">
                                <FederationEntrepriseLogo style={logoProps}/>
                            </div>
                            <div className="ZeroBarrier-partners">
                                <CroixRougeLogo style={logoProps}/>
                            </div>
                            <div className="ZeroBarrier-partners">
                                <CCALogo style={logoProps}/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Layout>
        </>
    );
}
