import { useState } from 'react';

// MUI
import { Grid } from '@mui/material';
import { Event as EventIcon, Place as PlaceIcon, Euro as EuroIcon, Info as InfoIcon } from '@mui/icons-material';

// CSS
import styles from './Bafa.module.css';

// Assets
import BafaLogo from 'assets/images/bafa/bafa512.png';
import DiscoviaLogo from 'assets/images/bafa/discovia.png';
import OpalLogo from 'assets/images/bafa/opal.png';
import RoadmapImg from 'assets/images/bafa/roadmap.png';
import CafBasRhin from 'assets/images/bafa/caf-basrhin.png';
import CafHautRhin from 'assets/images/bafa/caf-hautrhin.png';
import LogoStrasbourg from 'assets/images/bafa/logo-strasbourg.png';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- BAFA ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Bafa() {
    return (
        <Grid container>
            <Grid
                item md={1} lg={2} xl={3} 
            />
            <Grid item xs={12} md={10} lg={8} xl={6}>
                <Header />
                <Presentation />
                <Roadmap />
                <Footer />
            </Grid>
        </Grid>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- HEADER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function Header() {
    return (
        <div className={styles.header}>
            {/* -------------------- DISCOVIA LOGO -------------------- */}
            <img
                alt="Logo Discovia"
                src={DiscoviaLogo}
                width={152}
                height={40}
                loading="eager"
                className={styles.discoviaLogo}
            />
            {/* -------------------- OPAL LOGO -------------------- */}
            <img
                alt="Logo Opal"
                src={OpalLogo}
                width={135}
                height={40}
                loading="eager"
                className={styles.opalLogo}
            />
            {/* -------------------- BAFA LOGO -------------------- */}
            <img
                alt="Logo Bafa"
                src={BafaLogo}
                width={160}
                height={160}
                loading="eager"
                className={styles.bafaLogo}
            />
            {/* -------------------- ABSOLUTE CONTAINER -------------------- */}
            <Grid
                position="absolute"
                right={0}
                left={0}
                bottom={-100}
                padding={2}
            >
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs="auto">
                        {/* -------------------- VIENS VIVRE -------------------- */}
                        <div className={styles.viensVivre}>
                            Viens vivre
                        </div>
                        {/* -------------------- UNE EXPERIENCE DE FORMATION -------------------- */}
                        <div className={styles.uneExperienceDeFormation}>
                            Une expérience de formation
                        </div>
                        {/* -------------------- DEVIENS ANIMATEUR -------------------- */}
                        <div className={styles.deviensAnimateur}>
                            Deviens animateur !
                        </div>
                    </Grid>
                    <Grid
                        item xs={12} md
                        container
                        justifyContent="flex-end"
                    >
                        {/* -------------------- TRAINING DETAILS -------------------- */}
                        <div className={styles.trainingDetails}>
                            <Grid
                                container
                                justifyContent="center"
                                textAlign="center"
                                textTransform="uppercase"
                                fontWeight={600}
                                fontSize={16}
                                className={styles.magentaColor}
                            >
                                Formation générale BAFA1
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                marginTop={1}
                            >
                                <EventIcon sx={{ fontSize: 20 }} />
                                <Grid
                                    item xs
                                    marginLeft={1}
                                >
                                    Du samedi 06 au samedi 13 juillet 2024
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                marginTop={1}
                            >
                                <InfoIcon sx={{ fontSize: 20 }} />
                                <Grid
                                    item xs
                                    marginLeft={1}
                                >
                                    En externat
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                marginTop={1}
                            >
                                <PlaceIcon sx={{ fontSize: 20 }} />
                                <Grid
                                    item xs
                                    marginLeft={1}
                                >
                                    La Régie des Écrivains
                                    <br />
                                    11 Rue de Lisbonne, 67300 Schiltigheim
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                alignItems="center"
                                marginTop={1}
                            >
                                <EuroIcon sx={{ fontSize: 20 }} />
                                <Grid
                                    item xs
                                    marginLeft={1}
                                >
                                    380 euros
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ PRESENTATION -------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function Presentation() {

    const [readMore, setReadMore] = useState(false);

    return (
        <>
            <Grid
                container
                padding={2}
                fontSize={16}
                textAlign="justify"
            >
                Le Brevet d’Aptitude aux Fonctions d’Animateur (BAFA) est un cursus de formation reconnu en France, destiné à préparer les futurs animateurs à encadrer des activités auprès d’enfants et d’adolescents. 
                Le BAFA est une étape cruciale pour ceux qui souhaitent travailler dans le domaine de l’animation.
                Il faut être âgé d’au moins 16 ans. Il n’y a pas de limite d’âge supérieure. 
                La formation est ouverte à tous, sans condition de diplôme préalable.
            </Grid>
            <Grid
                container
                alignItems="flex-start"
            >
                {/* -------------------- DISCOVIA OPAL -------------------- */}
                <Grid
                    item xs={12} md={6}
                    padding={2}
                >
                    <Grid 
                        container
                        justifyContent="center"
                        textAlign="center"
                    >
                        <div className={styles.blueContainerWithArrow}>
                            Discovia Opal
                        </div>
                        <div className={styles.paragraphBelowBlueContainerWithArrow}>
                            <p>
                                Passionné par l'éducation et l'animation jeunesse ?
                            </p>
                            <p>
                                Aspirez à une formation BAFA de haute qualité avec DISCOVIA OPAL !
                            </p>
                            <p>
                                Notre collaboration unique associe l'expertise d'OPAL, un acteur clé de l'éducation populaire spécialisé dans l'accueil
                                des enfants de 3 à 12 ans, avec l'excellence de DISCOVIA, reconnu pour son expertise en ingénierie de formation.
                                Cette synergie offre une expérience d'apprentissage complète, combinant expérience pratique et expertise pédagogique de premier ordre.
                            </p>
                            <p>
                                Préparez-vous à relever les défis de l’animation et contribuer à un avenir éducatif plus enrichissant et inclusif.
                            </p>
                            <p>
                                Devenez un animateur inspirant pour la nouvelle génération avec DISCOVIA OPAL.
                            </p>
                            <p>
                                Rejoignez-nous pour une expérience formatrice riche et enthousiasmante !
                            </p>
                        </div>
                    </Grid>
                </Grid>
                {/* -------------------- PROJET EDUCATIF -------------------- */}
                <Grid
                    item xs={12} md={6}
                    padding={2}
                >
                    <Grid 
                        container
                        justifyContent="center"
                    >
                        <div className={styles.blueContainerWithArrow}>
                            Projet éducatif
                        </div>
                        <div className={styles.paragraphBelowBlueContainerWithArrow}>
                            <p>
                                Rejoignez notre aventure éducative avec le BAFA !
                            </p>
                            <p>
                                Nous favorisons la diversité et éveillons les esprits aux valeurs essentielles telles que la solidarité, l'inclusion,
                                et la tolérance. Nous vous proposons une expérience où chaque voix compte et chaque différence est une force !
                            </p>
                            <p>
                                Nous voyons en chacun un potentiel unique. Nos formations sont plus qu'un apprentissage ;
                                elles sont un tremplin vers l'engagement citoyen et la formation d’animateurs responsables.
                                Ici, vous n'apprenez pas seulement, vous évoluez en citoyens actifs et impliqués.
                            </p>
                            <p>
                                Préparez-vous à explorer la dynamique du travail en équipe et à plonger dans un apprentissage interactif.
                                Nous mettons l'accent sur la créativité collective, transformant chaque idée en une aventure passionnante.
                            </p>
                            {readMore ? (
                                <>
                                    <p>
                                        Nos formations BAFA sont ancrées dans des scénarios réels, stimulant l'apprentissage actif et la réflexion critique.
                                        Vous deviendrez des acteurs proactifs de votre formation, préparés à faire face à tout défi avec confiance et créativité.
                                    </p>
                                    <p>
                                        Du rôle essentiel de l'animateur à la conception de projets innovants,
                                        nos formations vous permettent de développer un éventail de compétences - pédagogie, communication,
                                        gestion de groupe, connaissance de l'enfance et de l'adolescence, créativité, organisation, adaptabilité, éthique professionnelle,
                                        autonomie et travail en équipe.
                                    </p>
                                    <p>
                                        Le BAFA est votre premier pas vers une carrière riche et gratifiante dans l'animation.
                                        Avec nous, transformez votre passion en un chemin professionnel prometteur,
                                        ouvrant les portes à des certifications professionnelles et à de nouvelles opportunités.
                                    </p>
                                    <p>
                                        Votre aventure commence ici ! Rejoignez nos formations BAFA et devenez l'animateur que vous avez toujours rêvé d'être.
                                    </p>
                                    <p>
                                        Inscrivez-vous dès maintenant et faites partie de cette expérience transformatrice !
                                    </p>
                                    <button
                                        onClick={() => setReadMore(false)}
                                        className={styles.readMoreButton}
                                    >
                                        Afficher moins...
                                    </button>
                                </>
                            ) : (
                                <button
                                    onClick={() => setReadMore(true)}
                                    className={styles.readMoreButton}
                                >
                                    Lire la suite...
                                </button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- ROADMAP ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function Roadmap() {
    return (
        <img
            alt="Parcours BAFA"
            src={RoadmapImg}
            loading="lazy"
            className={styles.roadmapImg}
        />
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- FOOTER ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

function Footer() {
    return (
        <div className={styles.waveFooter}>
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
            >
                <path
                    d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                    className={styles.waveShape}
                />
            </svg>
            <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScozDAJiLQBcgaIjMAwM19IZudi0xP1odYL9t5iiOdedPOdwA/viewform?usp=pp_url"
                target="_blank"
                rel="noreferrer"
                className={styles.inscriptionButton}
            >
                Inscription
            </a>
            <img
                alt="Strasbourg"
                src={LogoStrasbourg}
                className={styles.logoStrasbourg}
            />
            <img
                alt="CAF Bas-Rhin"
                src={CafBasRhin}
                className={styles.cafImg}
                style={{ right: 8 }}
            />
            <img
                alt="CAF Haut-Rhin"
                src={CafHautRhin}
                className={styles.cafImg}
                style={{ right: 116 }}
            />
        </div>
    );
}
