import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
import translationEN from 'assets/translations/en.translations.json';
import translationFR from 'assets/translations/fr.translations.json';

// Resources
const resources = {
    "en": {
        translation: translationEN,
    },
    "fr": {
        translation: translationFR,
    },
};

// --------------------------------------------------------------------------------- \\
// -------------------------------- I18NEXT CONFIG --------------------------------- \\
// --------------------------------------------------------------------------------- \\

i18next.use(initReactI18next).init({
    resources,
    lng: 'fr',
    fallbackLng: {
        default: ['en'],
    },
});

export default i18next;
