import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './Solutions.css';

// Lodash
import { debounce } from 'lodash';

// MUI
import { Grid, Button, Fab } from '@mui/material';
import { ArrowUpward as ArrowUpIcon } from '@mui/icons-material';

// Services
import { API_URL, blueColor, magentaColor, greenColor } from 'services/constants';
import { useMediaQueries } from 'services/hooks';

// Components
import HeadAttributes from 'components/HeadAttributes';
import Layout from 'components/Layout';

// Assets
import DiscoviaCircle from 'assets/images/solutions/zerobarrier-circle.png';

// Views
import ZeroBarrier from 'views/ZeroBarrier';
import HappInCo from 'views/HappInCo';
import DigitalWall from 'views/DigitalWall';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- SOLUTIONS ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Solutions() {

    const { t } = useTranslation();
    const { mediaDesktop } = useMediaQueries();
    const [scrollTopEnabled, setScrollTopEnabled] = useState(false);
    
    const onScrollTo = (sectionName: 'zerobarrier' | 'happinco' | 'digitalwall') => {

        let section : HTMLElement | null = null;

        switch (sectionName) {

            case 'zerobarrier':
                section = document.getElementById('ZeroBarrier-section');
                break;

            case 'happinco':
                section = document.getElementById('HappInCo-section');
                break;

            case 'digitalwall':
                section = document.getElementById('DigitalWall-section');
                break;
        }

        if (section) {
            window.scroll({ left: 0, top: section.offsetTop - 48, behavior: 'smooth' });
        }
    };

    useEffect(() => {

        if (window.scrollY > 0) {
            window.scroll(0, 0);
        }

        const onScroll = () => {
            if (window.scrollY === 0) {
                setScrollTopEnabled(false);
            }
            if (window.scrollY > 0) {
                setScrollTopEnabled(true);
            }
        };
        window.addEventListener('scroll', debounce(onScroll, 300));

        return () => {
            window.removeEventListener('scroll', onScroll);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <HeadAttributes
                title={t('solutions')}
                canonical={API_URL + 'solutions'}
            />
            {/* -------------------- TRUST US : TITLE -------------------- */}
            <Layout>
                <div className="title-container">
                    <h1> {t('solutions_translations.1')}</h1>
                </div>
            </Layout>
            {/* -------------------- TRUST US : PARTNER LOGOS -------------------- */}
            <div className="Solutions-theyTrustUs">
                <div className="Solutions-theyTrustUs-grid">
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-AG2R"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-ENGIE"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-MFR"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-CroixRouge"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-FranceActive"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-Chenelet"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-IDEES21"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-AFPA"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-ARES"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-MinistereTravail"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-FEI"
                        />
                    </div>
                    <div className="Solutions-theyTrustUs-item">
                        <div
                            className="Solutions-item Solutions-VALO"
                        />
                    </div>
                </div>
            </div>
            <Layout>
                {/* -------------------- TOOLS : TITLE -------------------- */}
                <div className="title-container">
                    <h1>{t('solutions_translations.2')}</h1>
                </div>
                <div style={{ padding: '0px 24px', textAlign: 'justify' }}>
                    {t('solutions_translations.3')}
                </div>
                <Grid
                    container spacing={3}
                    style={{ padding: 24 }}
                >
                    {/* -------------------- ZERO BARRIER -------------------- */}
                    <Grid
                        item xs={12} md={6} xl={4}
                        container justifyContent="center"
                    >
                        <div>
                            <div className="Solutions-card">
                                <div className="Solutions-card-title">
                                    ZERO
                                    <img
                                        alt="Cercle du logo DISCOVIA"
                                        src={DiscoviaCircle}
                                        width={40}
                                        height={40}
                                        loading="eager"
                                        style={{ margin: '0px 6px' }}
                                    />
                                    BARRIER
                                </div>
                                <div
                                    className="Solutions-card-image Solutions-ZEROBARRIER"
                                />
                                <div
                                    className="Solutions-card-subtitle"
                                    style={{ color: blueColor }}
                                >
                                    {t('solutions_translations.4')}
                                </div>
                            </div>
                            <div className="Solutions-navButton">
                                <Button
                                    onClick={() => onScrollTo('zerobarrier')}
                                    variant="contained" color="primary"
                                >
                                    {t('solutions_translations.7')}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- HAPP'IN CO -------------------- */}
                    <Grid
                        item xs={12} md={6} xl={4}
                        container justifyContent="center"
                    >
                        <div>
                            <div className="Solutions-card">
                                <div className="Solutions-card-title">
                                    BE HAPPY
                                    <img
                                        alt="Cercle du logo DISCOVIA"
                                        src={DiscoviaCircle}
                                        width={40}
                                        height={40}
                                        loading="lazy"
                                        style={{ margin: '0px 6px' }}
                                    />
                                    IN COMPANY
                                </div>
                                <div
                                    className="Solutions-card-image Solutions-HAPPINCO"
                                />
                                <div
                                    className="Solutions-card-subtitle"
                                    style={{ color: magentaColor }}
                                >
                                    {t('solutions_translations.5')}
                                </div>
                            </div>
                            <div className="Solutions-navButton">
                                <Button
                                    onClick={() => onScrollTo('happinco')}
                                    variant="contained" color="primary"
                                >
                                    {t('solutions_translations.7')}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- DIGITAL WALL -------------------- */}
                    <Grid
                        item xs={12} md={6} xl={4}
                        container justifyContent="center"
                    >
                        <div>
                            <div className="Solutions-card">
                                <div className="Solutions-card-title">
                                    MUR
                                    <img
                                        alt="Cercle du logo DISCOVIA"
                                        src={DiscoviaCircle}
                                        width={40}
                                        height={40}
                                        loading="lazy"
                                        style={{ margin: '0px 6px' }}
                                    />
                                    SOFT SKILLS
                                </div>
                                <div
                                    className="Solutions-card-image Solutions-EXPERTS"
                                />
                                <div
                                    className="Solutions-card-subtitle"
                                    style={{ color: greenColor }}
                                >
                                    {t('solutions_translations.6')}
                                </div>
                            </div>
                            <div className="Solutions-navButton">
                                <Button
                                    onClick={() => onScrollTo('digitalwall')}
                                    variant="contained" color="primary"
                                >
                                    {t('solutions_translations.7')}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Layout>
            {/* -------------------- SOLUTIONS -------------------- */}
            <ZeroBarrier />
            <HappInCo />
            <DigitalWall />
            {/* -------------------- SCROLL TO TOP -------------------- */}
            {(mediaDesktop && scrollTopEnabled) && (
                <Fab
                    onClick={() => window.scroll({ left: 0, top: 0, behavior: 'smooth' })}
                    color="primary" size="medium"
                    style={{ position: 'fixed', bottom: 12, right: 12 }}
                >
                    <ArrowUpIcon />
                </Fab>
            )}
        </>
    );
}
