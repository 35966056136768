import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './Home.css';

// MUI
import { Divider, Grid } from '@mui/material';

// Services
import { API_URL } from 'services/constants';
import { useMediaQueries } from 'services/hooks';

// Components
import HeadAttributes from 'components/HeadAttributes';
import Layout from 'components/Layout';

/* * *
* Assets
*/

import { ReactComponent as AG2RLogo } from 'assets/images/partners/ag2r.svg';
import { ReactComponent as EngieLogo } from 'assets/images/partners/engie.svg';
import { ReactComponent as MinistereEnseignementLogo } from 'assets/images/partners/ministere-enseignement.svg';
import { ReactComponent as CroixRougeLogo } from 'assets/images/partners/croix-rouge.svg';
import { ReactComponent as EqavetLogo } from 'assets/images/partners/eqavet.svg';
import { ReactComponent as ErasmusLogo } from 'assets/images/partners/erasmus.svg';
import { ReactComponent as FEILogo } from 'assets/images/partners/fei.svg';
import { ReactComponent as FranceActiveLogo } from 'assets/images/partners/france-active.svg';
import { ReactComponent as FranceCompetenceLogo } from 'assets/images/partners/france-competence.svg';
import { ReactComponent as MFRLogo } from 'assets/images/partners/mfr.svg';
import { ReactComponent as EQFLogo } from 'assets/images/partners/eqf.svg';
import { ReactComponent as EuropassLogo } from 'assets/images/partners/europass.svg';

// Logo Props
const logoProps : React.CSSProperties = {
    width: '100%',
    maxWidth: 120,
    height: 'auto',
    maxHeight: 60,
};

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- HOME ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Home() {

    const { t } = useTranslation();
    const { mediaDesktop } = useMediaQueries();

    useEffect(() => {
        if (window.scrollY > 0) {
            window.scroll(0, 0);
        }
    }, []);

    return (
        <>
            <HeadAttributes
                title={t('home')}
                canonical={API_URL + 'home'}
                description="Discovia permet aux entreprises de valoriser et développer les compétences de leurs salariés et de les accompagner vers la certification."
            />
            <div className="Home">
                <Layout containerProps={{ flex: 1, padding: '48px 24px' }}>
                    <div className="Home-video">
                        <video
                            autoPlay={mediaDesktop}
                            muted
                            controls
                            src="https://discovia.fr/resources/home-video.mp4"
                        />
                    </div>
                </Layout>
                <Divider />
                {/* ------------------ PARTNERS ------------------ */}
                <Grid
                    container spacing={3}
                    alignItems="center" justifyContent="center"
                    style={{ padding: 24 }}
                >
                    <Grid item xs="auto">
                        <AG2RLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <EngieLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <MinistereEnseignementLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <CroixRougeLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <FranceActiveLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <FranceCompetenceLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <FEILogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <MFRLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <EQFLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <ErasmusLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <EuropassLogo style={logoProps} />
                    </Grid>
                    <Grid item xs="auto">
                        <EqavetLogo style={logoProps} />
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <Layout>
                <div style={{ padding: '48px 24px' }}>
                    {/* -------------------- TITLE -------------------- */}
                    <div>
                        <h1>{t('home_translations.2')}</h1>
                    </div>
                    {/* -------------------- EDTECH -------------------- */}
                    <div style={{ marginTop: 48, textAlign: 'justify' }}>
                        <b>{t('home_translations.3')}</b>
                    </div>
                    {/* -------------------- MISSION -------------------- */}
                    <div style={{ marginTop: 48, textAlign: 'justify' }}>
                        <h2 style={{ color: 'gray' }}>
                            <b>{t('home_translations.4')}</b>
                        </h2>
                        {t('home_translations.5')}
                    </div>
                    {/* -------------------- VISION -------------------- */}
                    <div style={{ marginTop: 48, textAlign: 'justify' }}>
                        <h2 style={{ color: 'gray' }}>
                            <b>{t('home_translations.6')}</b>
                        </h2>
                        {t('home_translations.7')}
                    </div>
                    {/* -------------------- SOLUTIONS -------------------- */}
                    <div style={{ marginTop: 48, textAlign: 'justify' }}>
                        <h2 style={{ color: 'gray' }}>
                            <b>{t('home_translations.8')}</b>
                        </h2>
                        {t('home_translations.9')}
                    </div>
                </div>
            </Layout>
        </>
    );
}
