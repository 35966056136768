import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// CSS
import './Expertise.css';

// MUI
import { Button, Grid } from '@mui/material';
import { SendRounded as SendIcon } from '@mui/icons-material';

// Services
import { API_URL, blueColor, yellowColor, magentaColor } from 'services/constants';

// Components
import HeadAttributes from 'components/HeadAttributes';
import Layout from 'components/Layout';

// Assets
import AdrienGendronneauImg from 'assets/images/expertise/adrien-gendronneau.jpg';
import YannMengusImg from 'assets/images/expertise/yann-mengus.jpg';
import EvaGilgertImg from 'assets/images/expertise/eva-gilgert.jpg';
import PierreHoerterImg from 'assets/images/expertise/pierre-hoerter.jpg';
import JudithFisherImg from 'assets/images/expertise/judith-fisher.jpg';
import JeromeApffelImg from 'assets/images/expertise/jerome-apffel.jpg';
import MelanieArabouxImg from 'assets/images/expertise/melanie-araboux.png';
import VirginieDeParlaImg from 'assets/images/expertise/virginie-de-parla.png';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- EXPERTISE ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Expertise() {

    const { t } = useTranslation();
    
    useEffect(() => {
        if (window.scrollY > 0) {
            window.scroll(0, 0);
        }
    }, []);

    return (
        <>
            <HeadAttributes
                title={t('expertise')}
                canonical={API_URL + 'expertise'}
            />
            <Layout>
                {/* -------------------- OUR 3 POLES -------------------- */}
                <div className="title-container">
                    <h1>{t('expertise_translations.1')}</h1>
                </div>
            </Layout>
            <Layout containerProps={{ backgroundColor: 'rgb(235, 235, 235)' }}>
                <div style={{ padding: '48px 24px 24px 24px' }}>
                    <h1>{t('expertise_translations.2')}</h1>
                </div>
                <Grid
                    container spacing={3} justifyContent="space-evenly"
                    style={{ padding: 24 }}
                >
                    {/* -------------------- INNOVATION -------------------- */}
                    <Grid
                        item xs={12} md={6} xl={4}
                        container justifyContent="center"
                    >
                        <div className="Expertise-card">
                            <div className="Expertise-card-title">
                                <h3>{t('expertise_translations.6').toUpperCase()}</h3>
                            </div>
                            <div
                                className="Expertise-card-image Expertise-card-innovation"
                            />
                        </div>
                    </Grid>
                    {/* -------------------- FORMATION -------------------- */}
                    <Grid
                        item xs={12} md={6} xl={4}
                        container justifyContent="center"
                    >
                        <div className="Expertise-card">
                            <div className="Expertise-card-title">
                                <h3>{t('expertise_translations.7').toUpperCase()}</h3>
                            </div>
                            <div
                                className="Expertise-card-image Expertise-card-formation"
                            />
                        </div>
                    </Grid>
                    {/* -------------------- CERTIFIATION -------------------- */}
                    <Grid
                        item xs={12} md={6} xl={4}
                        container justifyContent="center"
                    >
                        <div className="Expertise-card">
                            <div className="Expertise-card-title">
                                <h3>{t('expertise_translations.8').toUpperCase()}</h3>
                            </div>
                            <div
                                className="Expertise-card-image Expertise-card-certification"
                            />
                        </div>
                    </Grid>
                </Grid>
                <div style={{ padding: '0px 24px 48px 24px', textAlign: 'justify' }}>
                    {t('expertise_translations.3')} : <b>{t('expertise_translations.4')}.</b>
                    <br />
                    {t('expertise_translations.5')}
                </div>
            </Layout>
            <Layout>
                {/* -------------------- POOL EXPERTS -------------------- */}
                <div className="title-container">
                    <h1>{t('expertise_translations.9')}</h1>
                </div>
                <div
                    className="Expertise-Experts"
                />
                <div style={{ padding: '24px 24px 0px 24px', textAlign: 'justify' }}>
                    <div>
                        {t('expertise_translations.10')}
                    </div>
                    <div style={{ marginTop: 24 }}>
                        {t('expertise_translations.11')}
                    </div>
                    <div style={{ marginTop: 24 }}>
                        <Button
                            href="mailto:contact@discovia.fr"
                            color="primary" variant="contained"
                            endIcon={<SendIcon />}
                        >
                            {t('contact_us')}
                        </Button>
                    </div>
                </div>
                {/* -------------------- POOL EXPERTS -------------------- */}
                <div className="title-container">
                    <h1>{t('expertise_translations.12')}</h1>
                </div>
                {/* -------------------- RESEARCH AND DEVELOPMENT -------------------- */}
                <div className="Expertise-sectionProfile">
                    <h2>{t('research_and_development')}</h2>
                </div>
                <Grid
                    container alignItems="flex-start" justifyContent="space-evenly"
                    spacing={3}
                    style={{ padding: 24 }}
                >
                    {/* -------------------- YANN MENGUS -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: blueColor }}
                            />
                            <div className="Expertise-profileImage">
                                <img
                                    alt="Yann MENGUS"
                                    src={YannMengusImg}
                                    width={192}
                                    height={192}
                                    loading="lazy"
                                />
                            </div>
                            <div className="Expertise-profileName">
                                Yann MENGUS
                            </div>
                            <div className="Expertise-description">
                                {t('yann_mengus.1')}
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- ADRIEN GENDRONNEAU -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: blueColor }}
                            />
                            <div className="Expertise-profileImage">
                                <img
                                    alt="Adrien GENDRONNEAU"
                                    src={AdrienGendronneauImg}
                                    width={192}
                                    height={192}
                                    loading="lazy"
                                />
                            </div>
                            <div className="Expertise-profileName">
                                Adrien GENDRONNEAU
                            </div>
                            <div className="Expertise-description">
                                {t('adrien_gendronneau.1')}
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- JEROME APFFEL -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: blueColor }}
                            />
                            <div className="Expertise-profileImage">
                                <img
                                    alt="Jérome APFFEL"
                                    src={JeromeApffelImg}
                                    width={192}
                                    height={192}
                                    loading="lazy"
                                />
                            </div>
                            <div className="Expertise-profileName">
                                Jérome APFFEL
                            </div>
                            <div className="Expertise-description">
                                {t('jerome_apffel.1')}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {/* -------------------- ENGINEERING TRAINING -------------------- */}
                <div className="Expertise-sectionProfile">
                    <h2>{t('engineering_training')}</h2>
                </div>
                <Grid
                    container alignItems="flex-start" justifyContent="space-evenly"
                    spacing={3}
                    style={{ padding: 24 }}
                >
                    {/* -------------------- EVA GILGERT -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: yellowColor }}
                            />
                            <div className="Expertise-profileImage">
                                <img
                                    alt="Eva GILGERT"
                                    src={EvaGilgertImg}
                                    width={200}
                                    height={200}
                                    loading="lazy"
                                />
                            </div>
                            <div className="Expertise-profileName">
                                Eva GILGERT
                            </div>
                            <div className="Expertise-description">
                                {t('eva_gilgert.1')}
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- MELANIE ARABOUX -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: yellowColor }}
                            />
                            <div
                                className="Expertise-profileImage"
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <div className="Expertise-profileImagePNG">
                                    <img
                                        alt="Mélanie ARABOUX"
                                        src={MelanieArabouxImg}
                                        width={300}
                                        height={200}
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div className="Expertise-profileName">
                                Mélanie ARABOUX
                            </div>
                            <div className="Expertise-description">
                                {t('melanie_araboux.1')}
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- VIRGINIE DE PARLA -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: yellowColor }}
                            />
                            <div 
                                className="Expertise-profileImage"
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <div className="Expertise-profileImagePNG">
                                    <img
                                        alt="Virginie DE PARLA"
                                        src={VirginieDeParlaImg}
                                        width={300}
                                        height={200}
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div className="Expertise-profileName">
                                Virginie DE PARLA
                            </div>
                            <div className="Expertise-description">
                                {t('virginie_de_parla.1')}
                                <br />
                                {t('virginie_de_parla.2')}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {/* -------------------- CERTIFICATION -------------------- */}
                <div className="Expertise-sectionProfile">
                    <h2>{t('engineering_certification')}</h2>
                </div>
                <Grid
                    container alignItems="flex-start" justifyContent="space-evenly"
                    spacing={3}
                    style={{ padding: 24, marginBottom: 24 }}
                >
                    {/* -------------------- PIERRE HOERTER -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: magentaColor }}
                            />
                            <div className="Expertise-profileImage">
                                <img
                                    alt="Pierre HOERTER"
                                    src={PierreHoerterImg}
                                    width={200}
                                    height={200}
                                    loading="lazy"
                                />
                            </div>
                            <div className="Expertise-profileName">
                                Pierre HOERTER
                            </div>
                            <div className="Expertise-description">
                                {t('pierre_hoerter.1')}
                            </div>
                        </div>
                    </Grid>
                    {/* -------------------- JUDITH FISHER -------------------- */}
                    <Grid item xs={12} sm={6} xl={4}>
                        <div className="Expertise-profile">
                            <div
                                className="Expertise-profileBackground"
                                style={{ backgroundColor: magentaColor }}
                            />
                            <div className="Expertise-profileImage">
                                <img
                                    alt="Judith FISCHER"
                                    src={JudithFisherImg}
                                    width={200}
                                    height={200}
                                    loading="lazy"
                                />
                            </div>
                            <div className="Expertise-profileName">
                                Judith FISCHER
                            </div>
                            <div className="Expertise-description">
                                {t('judith_fischer.1')}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Layout>
        </>
    );
}
