
// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- TRUNCATE LABEL ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const truncateLabel = (label: string, index: number) => {
    if (label.length > index) {
        return `${label.substring(0, index).trimEnd()}...`;
    }
    return label;
};
