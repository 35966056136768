import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

// CSS
import './Footer.css';

// MUI
import { Grid, IconButton } from '@mui/material';
import { LinkedIn as LinkedInIcon } from '@mui/icons-material';

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- FOOTER ------------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Footer() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <footer>
            <Grid container alignItems="start">
                <Grid 
                    item xs={12} md={4}
                    container flexDirection="column"
                    alignItems="center" justifyContent="flex-start"
                    className="Footer-gridItem"
                >
                    <div>
                        <h3>PLAN DU SITE</h3>
                        <ul className="Footer-ul">
                            <li onClick={() => navigate('/home')}>
                                {t('home')}
                            </li>
                            <li onClick={() => navigate('/solutions')}>
                                {t('solutions')}
                            </li>
                            <li onClick={() => navigate('/expertise')}>
                                {t('expertise')}
                            </li>
                            <li onClick={() => navigate('/about-us')}>
                                {t('about_us')}
                            </li>
                            {/* <li onClick={() => navigate('/contact')}>
                                {t('contact')}
                            </li> */}
                        </ul>
                    </div>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container flexDirection="column"
                    alignItems="center" justifyContent="flex-start"
                    className="Footer-gridItem"
                >
                    <div>
                        <h3>{t('contact').toUpperCase()}</h3>
                        <ul className="Footer-ul">
                            <li>
                                +33 (0)9 78 80 14 13
                            </li>
                            <li>
                                <a
                                    href="mailto:contact@discovia.fr"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    contact@discovia.fr
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://goo.gl/maps/Ga3sLearedVbXcJw5"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    17 Rue de la Boucherie, 67120 Molsheim
                                </a>
                            </li>
                        </ul>
                    </div>
                </Grid>
                <Grid
                    item xs={12} md={4}
                    container flexDirection="column"
                    alignItems="center" justifyContent="flex-start"
                    className="Footer-gridItem"
                >
                    <div>
                        <h3>{t('follow_us').toUpperCase()}</h3>
                        <IconButton
                            href="https://www.linkedin.com/company/discovia-fr/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ padding: 0, marginTop: 24 }}
                        >
                            <LinkedInIcon
                                fontSize="large"
                                style={{ color: 'white' }}
                            />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <Grid
                container justifyContent="center" alignItems="center"
                className="Footer-baseline"
            >
                <span>
                    ©{new Date().getFullYear()} DISCOVIA.
                </span>
                <Link
                    to="/legal-notice"
                    className="Footer-legalNotice"
                >
                    {t('legal_notice')}.
                </Link>
            </Grid>
        </footer>
    );
}
