import { PropsWithChildren } from 'react';

// MUI
import { Button } from '@mui/material';
import { Close as CrossIcon } from '@mui/icons-material';

// Assets
import LogoDiscovia from 'assets/images/locomotive/logo-discovia.png';
import LogoLocomotive from 'assets/images/locomotive/logo-lalocomotive.png';

// CSS
import styles from './Layout.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- LAYOUT ------------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface ILayoutProps extends PropsWithChildren {
    title: string;
    onClickToBackButton?: VoidFunction;
}

export const Layout = (props: ILayoutProps) => {
    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {props.title}
                </div>
                <div className={styles.logo}>
                    <img
                        alt="Discovia"
                        src={LogoDiscovia}
                        width={120}
                        height={32}
                        loading="eager"
                    />
                    <CrossIcon
                        htmlColor="gray"
                        style={{ margin: '0px 16px' }}
                    />
                    <img
                        alt="Locomotive"
                        src={LogoLocomotive}
                        width={73}
                        height={60}
                        loading="eager"
                    />
                </div>
            </div>
            {(props.onClickToBackButton) && (
                <div className={styles.backButtonContainer}>
                    <Button
                        onClick={props.onClickToBackButton}
                        variant="contained"
                        color="inherit"
                    >
                        Retour
                    </Button>
                </div>
            )}
            <div className={styles.wrapper}>
                {props.children}
            </div>
        </div>
    );
}

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ NAV BUTTON ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

interface INavButtonProps {
    onClickBtn1: VoidFunction;
    onClickBtn2: VoidFunction;
    srcBtn1: string;
    srcBtn2: string;
}

export const NavButton = (props: INavButtonProps) => {
    return (
        <div className={styles.navContainer}>
            <Button
                onClick={props.onClickBtn1}
                variant="contained"
                color="inherit"
                className={styles.navButton}
                sx={{ borderRadius: 8, width: 380, height: 380 }}
                style={{ backgroundImage: props.srcBtn1 ? `url(${props.srcBtn1})` : undefined }}
            />
            <Button
                onClick={props.onClickBtn2}
                variant="contained"
                color="inherit"
                className={styles.navButton}
                sx={{ borderRadius: 8, width: 380, height: 380 }}
                style={{ backgroundImage: props.srcBtn2 ? `url(${props.srcBtn2})` : undefined }}
            />
        </div>
    );
}
